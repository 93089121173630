import "./AddTimeEntry.scss";

import { FormControl, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AmountDialog from "../../../components/common/amountDialog.jsx";
import AutoComplete from "../../../components/common/AutoComplete.jsx";
import AutoCompleteCustom from "../../../components/common/AutoCompleteCustom.jsx";
import ComplianceTextField from "../../../components/common/ComplianceTextField.jsx";
import CssTextField from "../../../components/common/CssTextField.jsx";
import DateRangeIcon from "@mui/icons-material/DateRange";
import InputAdornment from "@mui/material/InputAdornment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MenuItem from "@mui/material/MenuItem";
import StyledButton from "../../../components/common/StyledButton.jsx";
import StyledDatePicker from "../../../components/common/StyledDatePicker.jsx";
import StyledTooltip from "../../../components/common/StyledTooltip";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import jsUtils from "../../../utils/jsUtils.js";
import { literals } from "../../../enums/literalCodes.js";
import timeEntryService from "../../../services/timeEntryService";
import { useDispatch, useSelector } from "react-redux";
import { ToggleTimeEntryData } from "../../../redux-toolkit/slices/DashboardTabs.js";

const TimeEntryFields = ({
  DNC,
  handleDNC,
  handleChange,
  state,
  errors,
  setState,
  setErrors,
  handleDate,
  handleHours,
  handleDiscount,
  isOpenFromModel,
  activityId = null,
  setPrevState,
  dictionary,
  handleRate,
  selectedOptionTimekeeper,
  setSelectedOptionTimekeeper,
}) => {
  const [openType, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [entryTypeList, setEntryTypeList] = useState([]);
  const [openDatePicker, setopenDatePicker] = useState(false);

  const inputRefNarrative = useRef(null);
  const inputRefNotes = useRef(null);
  const dispatch=useDispatch();
  const theme = useTheme();

  //Add states for ClientMatter, Phase, taskCode etc here...
  const [selectedOptionClientMatter, setSelectedOptionClientMatter] = useState("");
  const [selectedOptionLocations, setSelectedOptionLocations] = useState(null);
  const [selectedOptionActivityCode, setSelectedOptionActivityCode] = useState(null);
  const [selectedOptionTaskCode, setSelectedOptionTaskCode] = useState(null);
  const { TimeEntryData } = useSelector((state) => state.tab);

  //Add options to what to show in the selected autocomplete field here...
  //Below could be removed the below code if we get "decription" by every autocomplete API.
  const OptionDescription = (option) => (option?.code ? option.code + " - " : "") + (option.description || "");
  const timeKeeperDescription = (option) => option.description || "";

  const geoLocationOptionLabel = (option) => {
    if (option) {
      return option.code + "-" + option.description || "";
    } else {
      let empty = "";
      return empty;
    }
  };

  const handleDatePicker = () => {
    setopenDatePicker(!openDatePicker);
  };

  const getCalendarIcon = (error) => {
    return (
      <>
        <Tooltip title="Open Calendar">
          <DateRangeIcon onClick={() => handleDatePicker()} />
        </Tooltip>
        {!!error && <StyledTooltip title={error.message} severity={error.errorLevel} placement="left" arrow />}
      </>
    );
  };

  //The below function could be considered unnecessary or having extra code.
  //It could be removed when the same ID parameters obtained from the autocomplete API
  // is matched with the payload in the Save/Post time entry API.
  const setOptionsChanges = (options, field) => {
    if (options) {
      switch (field) {
        case "customerId": {
          let { matterId, clientId, enforceBillableFlag, billableType } = options;
          let { billable, billableCode } = jsUtils.getBillableInfo(billableType);

          setState((prevState) => ({
            ...prevState,
            customerId: parseInt(matterId),
            clientId: parseInt(clientId),
            enforceBillableFlag,
            billable,
            billableCode,
          }));

          break;
        }

        case "employeeId": {
          let employeeId = parseInt(options.id);
          let taxExempt = options.employeeEntity.taxExempt ? "Yes" : "No";

          setState((prevState) => ({
            ...prevState,
            employeeId: employeeId,
            taxExempt: taxExempt,
          }));
          dispatch(ToggleTimeEntryData({...TimeEntryData,employeeId:options}));
          break;
        }

        case "geoLocationId": {
          let geoLocationId = parseInt(options.id);
          setState((prevState) => ({
            ...prevState,
            geoLocationId: geoLocationId,
          }));
          break;
        }

        case "phase": {
          //logic for phase
          break;
        }
        case "activityCodeId": {
          let activityId = parseInt(options.id);
          setState((prevState) => ({
            ...prevState,
            [field]: activityId,
          }));

          break;
        }

        case "taskCodeId": {
          let taskId = parseInt(options.id);
          setState((prevState) => ({
            ...prevState,
            [field]: taskId,
          }));
          break;
        }
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [field]: null,
        billable: field === "customerId" ? 0 : prevState.billable,
        billableCode: field === "customerId" ? "" : prevState.billableCode,
        taxExempt: "",
      }));
    }

    if (field === "customerId") {
      setSelectedOptionTaskCode("");
      setSelectedOptionActivityCode("");
      setSelectedOptionTimekeeper("");
      setSelectedOptionLocations("");
    }
  };

  const getEntryTypeList = () => {
    timeEntryService
      .getEntryTypes()
      .then((data) => {
        if (data.data.getEntryTypes) {
          setEntryTypeList(data.data.getEntryTypes);
        } else {
          setEntryTypeList([]);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    getEntryTypeList();
    if (isOpenFromModel && activityId) {
      timeEntryService
        .getTimeEntryById(activityId)
        .then(async (data) => {
          if (data?.data?.getTimeEntryById) {
            let response = jsUtils.getStateForEdit(data?.data?.getTimeEntryById);

            let accountingResponse = await timeEntryService.getAccountingPeriod(dayjs(response.transactionDate).format("YYYY-MM-DD"));
            let periodDate = accountingResponse.code + " " + accountingResponse.status.status;

            setState({ ...response, periodDate });
            setPrevState({ ...response, periodDate });

            setSelectedOptionTimekeeper({
              description: response?.timekeeperDescription,
            });
            setSelectedOptionClientMatter({
              description: response?.clientMattersDescription,
            });
            response.activityCodeId &&
              setSelectedOptionActivityCode({
                code: response?.activityCode || "",
                description: response?.activityDescription || "",
              });
            response.geoLocationId &&
              setSelectedOptionLocations({
                code: response?.geoLocationCode || "",
                description: response?.geoLocationDescription || "",
              });
            response.taskCodeId &&
              setSelectedOptionTaskCode({
                code: response?.taskCode || "",
                description: response?.taskDescription || "",
              });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      setSelectedOptionClientMatter({
        description: state?.clientMatter,
      });
    }
  }, []);

  useEffect(() => {
    if (!isOpenFromModel) {
      if (Object.keys(TimeEntryData).length) {
        setSelectedOptionClientMatter({
          description: TimeEntryData?.customerId?.description,
        });
        setSelectedOptionTimekeeper({
          description: TimeEntryData?.employeeId?.description,
        });
        setSelectedOptionTaskCode({
          code: TimeEntryData?.taskCodeId?.code,
          description: TimeEntryData?.taskCodeId?.description,
        });
        setSelectedOptionActivityCode({
          code: TimeEntryData?.activityCodeId?.code,
          description: TimeEntryData?.activityCodeId?.description,
        });
        TimeEntryData?.geoLocationId &&
          setSelectedOptionLocations({
            code: TimeEntryData?.geoLocationId?.code,
            description: TimeEntryData?.geoLocationId?.description,
          });
      }
    }
  }, []);

  const draftOrTagged = state?.status == literals.DRAFT || state?.status == literals.TAGGED;

  const hoursSpinnersStyle = {
    right: errors.hours ? "28px" : "10px",
    cursor: !draftOrTagged && "default",
    color: !draftOrTagged && "rgba(0, 0, 0, 0.38)",
  };

  const customerIdAndDraft = typeof state?.customerId === "undefined" ? null : state?.customerId !== null && state?.status === literals.DRAFT;

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={1.5} item>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <AutoComplete
            testId={'client'}
            setOptionsChanges={setOptionsChanges}
            state={state}
            errors={errors}
            selectedOption={selectedOptionClientMatter}
            setSelectedOption={setSelectedOptionClientMatter}
            searchSuggestions={timeEntryService.searchClientMatters}
            getOptionLabel={OptionDescription}
            field={"customerId"}
            label={"Client/Matter"}
            disabled={state?.status !== literals.DRAFT}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <AutoCompleteCustom
            setOptionsChanges={setOptionsChanges}
            state={state}
            searchId={state.customerId}
            errors={errors}
            selectedTimekeeper={selectedOptionTimekeeper}
            setSelectedOption={setSelectedOptionTimekeeper}
            searchSuggestion={timeEntryService.getTimeKeepers}
            getOptionLabel={timeKeeperDescription}
            field={"employeeId"}
            label={"Timekeeper"}
            disabled={!customerIdAndDraft}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <CssTextField
            label="Entry Type"
            fullWidth
            size="small"
            select
            name="activityType"
            sx={{'& .MuiOutlinedInput-root.Mui-focused fieldset': {
            borderColor: '#0050C3',
            }}}
            InputLabelProps={{ shrink: true }}
            color="primary"
            onChange={handleChange}
            value={state.activityType}
            onClick={() => state?.status == literals.DRAFT && setOpen(!openType)}
            disabled={state?.status !== literals.DRAFT}
          >
            {entryTypeList.map((entry, index) => (
              <MenuItem key={index} value={entry.code}>
                {entry.description}
              </MenuItem>
            ))}
          </CssTextField>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledDatePicker
                label="Date"
                open={openDatePicker}
                onClose={() => handleDatePicker()}
                slotProps={{
                  textField: {
                    required: true,
                    error: !!errors.date,
                    InputLabelProps: { shrink: true },
                    InputProps: {
                      endAdornment: <InputAdornment position="end">
                                      {state?.status === literals.DRAFT && getCalendarIcon(errors.date)}
                                    </InputAdornment>,
                    },
                  },
                }}
                value={state.date}
                onChange={(date) => handleDate(date)}
                name="activityDate"
                InputLabelProps={{ shrink: true }}
                size="small"
                inputFormat="MM/DD/YYYY"
                color={jsUtils.getFieldSeverity(errors.date)}
                format="MM/DD/YYYY"
                disabled={state?.status !== literals.DRAFT}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <CssTextField
            label="Hours"
            fullWidth
            type="number"
            InputLabelProps={{ shrink: true }}
            required
            color={jsUtils.getFieldSeverity(errors.hours)}
            size="small"
            autoComplete="off"
            name="hours"
            value={state.hours}
            inputProps={{"data-testid":"hours"}}
            onChange={handleHours}
            error={!!errors.hours}
            // helperText={errors.hours}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="start">
                    <div className="spinners-main">
                      <KeyboardArrowUpIcon
                        onClick={() => draftOrTagged && handleHours("spinnerUpButton")}
                        className="spinnerTop"
                        style={hoursSpinnersStyle}
                      />
                      <KeyboardArrowDownIcon
                        onClick={() => draftOrTagged && handleHours("spinnerDownButton")}
                        className="spinnerBottom"
                        style={hoursSpinnersStyle}
                      />
                    </div>
                  </InputAdornment>

                  {!!errors.hours && (
                    <InputAdornment position="end">
                      <StyledTooltip title={errors.hours.message} severity={errors.hours.errorLevel} placement="left" arrow />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            disabled={!draftOrTagged}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
          <CssTextField
            label="Quantity"
            fullWidth
            type="number"
            color={jsUtils.getFieldSeverity(errors.quantity)}
            name="quantity"
            disabled={state.activityType !== "TaskFee" || state?.status === literals.POSTED}
            value={state.quantity}
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={handleRate}
            inputProps={{"data-testid":"quantity"}}
            error={!!errors.quantity}
            InputProps={{
              endAdornment: (
                <>
                  {!!errors.quantity && (
                    <InputAdornment position="end">
                      <StyledTooltip title={errors.quantity.message} severity={errors.quantity.errorLevel} placement="left" arrow />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
          <CssTextField
            label="Rate"
            fullWidth
            type="number"
            color={jsUtils.getFieldSeverity(errors.rate)}
            name="rate"
            disabled={state.activityType || state?.status === literals.POSTED}
            value={state.rate}
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={handleRate}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            error={!!errors.rate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
          <CssTextField
            label="Total"
            fullWidth
            type="number"
            color={jsUtils.getFieldSeverity(errors.amount)}
            name="amount"
            disabled={state.activityType !== "SimpleFee" || state?.status === literals.POSTED}
            value={state.amount}
            InputLabelProps={{ shrink: true }}
            size="small"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            error={!!errors.amount}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <AutoComplete
            testId={'Task'}
            setOptionsChanges={setOptionsChanges}
            state={state}
            searchId={state.customerId}
            errors={errors}
            selectedOption={selectedOptionTaskCode}
            setSelectedOption={setSelectedOptionTaskCode}
            searchSuggestions={timeEntryService.searchTaskCode}
            getOptionLabel={OptionDescription}
            field={"taskCodeId"}
            label="Task"
            disabled={!customerIdAndDraft}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <AutoComplete
            setOptionsChanges={setOptionsChanges}
            state={state}
            searchId={state.customerId}
            errors={errors}
            selectedOption={selectedOptionActivityCode}
            setSelectedOption={setSelectedOptionActivityCode}
            searchSuggestions={timeEntryService.searchActivityCode}
            getOptionLabel={OptionDescription}
            field={"activityCodeId"}
            label={"Activity"}
            disabled={!customerIdAndDraft}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <AutoComplete
            setOptionsChanges={setOptionsChanges}
            state={state}
            searchId={state.customerId}
            errors={errors}
            selectedOption={selectedOptionLocations}
            setSelectedOption={setSelectedOptionLocations}
            searchSuggestions={timeEntryService.searchLocations}
            getOptionLabel={geoLocationOptionLabel}
            field={"geoLocationId"}
            label={"Location"}
            disabled={!customerIdAndDraft}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <CssTextField
            label="Phase"
            fullWidth
            color="primary"
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={handleChange}
            name="phase"
            autoComplete="off"
            value={state.phase}
            error={!!errors.phase}
            helperText={errors.phase}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ComplianceTextField
            label="Narrative"
            isRequired={true}
            state={state}
            field="description"
            setState={setState}
            errors={errors}
            setErrors={setErrors}
            dictionary={dictionary}
            testId='narrative'
            inputRefTextField={inputRefNarrative}
            maxLength={8000}
            disabled={draftOrTagged || state?.status == literals.POSTED ? false : true}
            helperText={`Character count: ${state?.description?.length || 0} Word count: ${
              state?.description?.split(" ").filter((word) => word !== "")?.length || 0
            }`}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={9.5} xl={9.5}>
          <ComplianceTextField
            label="Notes"
            state={state}
            field="note"
            isRequired={false}
            setState={setState}
            errors={errors}
            setErrors={setErrors}
            dictionary={dictionary}
            testId='Notes'
            inputRefTextField={inputRefNotes}
            maxLength={2000}
            disabled={draftOrTagged || state?.status == literals.POSTED ? false : true}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={2.5} xl={2.5} container gap={1}>
          <StyledButton
            variant="outlined"
            style={{
              color: "#0050C3",
              marginTop: "0px",
              width: "100%",
              height: "31px",
              fontSize: "13px",
            }}
            onClick={() => setOpenDialog(!openDialog)}
            disabled={state?.status !== literals.DRAFT}
          >
            Discount
          </StyledButton>

          <StyledButton
            variant="outlined"
            onClick={handleDNC}
            style={{
              color: "#0050C3",
              marginTop: "0px",
              width: "100%",
              height: "31px",
              fontWeight: DNC ? "bold" : "500",
              fontSize: "13px",
              letterSpacing: "0",
            }}
            disabled={state?.status !== literals.DRAFT}
          >
            Do Not Charge
          </StyledButton>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={9.5} xl={9.5} container rowSpacing={2} columnSpacing={1.5}>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <CssTextField
              label="Billable"
              fullWidth
              color="primary"
              name="billable"
              value={state.billableCode}
              disabled={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              // error={!!errors.billable}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <CssTextField
              label="Transaction Date"
              fullWidth
              color="primary"
              value={state.transactionDate}
              name="transactionDate"
              disabled={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={!!errors.amount}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <CssTextField
              label="Period Date"
              fullWidth
              color="primary"
              name="periodDate"
              value={state?.periodDate}
              disabled={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={!!errors.amount}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <CssTextField
              label="Tax Exempt"
              fullWidth
              color="primary"
              name="tax"
              value={state.taxExempt}
              disabled={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={!!errors.amount}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2.5} xl={2.5}>
          <CssTextField
            label="Status"
            fullWidth
            color="primary"
            name="status"
            value={state?.customerId != null ? state?.status : ""}
            disabled={true}
            InputLabelProps={{ shrink: true }}
            size="small"
            error={!!errors.amount}
          />
        </Grid>
      </Grid>
      <AmountDialog open={openDialog} closeModel={() => setOpenDialog(false)} handleSubmit={handleDiscount} />
    </>
  );
};
export default memo(TimeEntryFields);
