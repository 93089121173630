import './AddTimeEntry.scss';

import {
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import {
  TimeEntryPostSchema,
  TimeEntrySaveSchema,
} from '../../../validationSchemas';
import {
  ToggleCheckStateForChanges,
  ToggleTimeEntryData,
  ToggleTimeEntryTabs,
  ToggleWantedToMove,
} from '../../../redux-toolkit/slices/DashboardTabs';
import { useDispatch, useSelector } from 'react-redux';

import AlertDialog from '../../../components/common/AlertDialog';
import ApolloContextProvider from '../../../GraphQL/ApolloContextProvider.js';
import CloseIcon from '@mui/icons-material/Close';
import CustomTimer from '../../../components/common/Timer';
import MainCard from '../../../components/common/MainCard.jsx';
import StyledButton from '../../../components/common/StyledButton';
import StyledLoadingButton from '../../../components/common/StyledLoadingButton';
import TimeEntryFields from './TimeEntryFields';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { fetchTimeEntryList } from '../../../redux-toolkit/slices/timeEntries.js';
import { getAllAutoCorrects } from '../../../redux-toolkit/slices/userSlice.js';
import jsUtils from '../../../utils/jsUtils.js';
import { literals } from '../../../enums/literalCodes';
import { openSnackbar } from '../../../redux-toolkit/slices/snackbar';
import { resetTimer } from '../../../redux-toolkit/slices/timer';
import { timeEntryModel } from '../../../models/timeEntryModel';
import timeEntryService from '../../../services/timeEntryService';
import { useAddTimeEntryContext } from '../../Dashboard/useAddTimeEntryContext.jsx';
import { useAutocomplete } from '../../../components/common/AutocompleteProvider';

const MIN_SECONDS = 360;
const INITIALSTATE = {
  clientMatter: '',
  activityId: null,
  activityTypeId: 15,
  activityDate: dayjs(new Date()).format('MM/DD/YYYY'),
  date: dayjs(new Date()),
  description: '',
  note: '',
  writeOff: false,
  doNotCharge: false,
  hours: '',
  isPost: true,
  amount: '',
  isNarrativeError: false,
  activityTime: '00:00:00',
  quantity: '',
  file: null,
  status: literals.DRAFT,
  customerId: null,
  activityType: 'TimeFee',
  geoLocationId: null,
  activityCodeId: null,
  employeeId: null,
  taskCodeId: null,
  billable: 0,
  billableCode: '',
  enforceBillableFlag: true,
  taxExempt: null,
  rate: 0,
};

const AddTimeEntry = ({
  isOpenFromModel = false,
  isOpenFromcalender = false,
  activityDate = '',
  selectedTimeEntry,
  selectedClientMatter,
  handleClose,
  removeWidgetFromDashboard,
  widgetId,
  previousTime = 0,
  isOpenFromRecentMattersModel = false,
  customerId,
  billableData
}) => {
  const updatedState = {
    ...INITIALSTATE,
    clientMatter: selectedClientMatter, // Override the clientMatter property
    customerId: customerId,
    transactionDate: isOpenFromRecentMattersModel
      ? dayjs(new Date()).format('MM/DD/YYYY')
      : '',
    enforceBillableFlag: billableData?.enforceBillable,
    billable: billableData?.billableId || billableData?.billable,
    billableCode: billableData?.billableCode,
  };

  let {
    addTimeEntryState: state,
    setAddTimeEntryState: setState,
    prevAddTimeEntryState: prevState,
    setPrevAddTimeEntryState: setPrevState,
  } = useAddTimeEntryContext(updatedState);

  if (
    isOpenFromModel ||
    isOpenFromRecentMattersModel ||
    isOpenFromcalender
  ) {
    [state, setState] = useState(updatedState)[
      (prevState, setPrevState)
    ] = useState(updatedState);
  }

  const { clearAll } = useAutocomplete();
  // const [isTracking, setisTracking] = useState(false)
  const [isSaving, setisSaving] = useState(false);
  const [isPosting, setisPosting] = useState(false);
  const [isUnPost, setIsUnPost] = useState(false);
  const [Modal, setModal] = useState(null);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [isAutoSaving, setisAutoSaving] = useState(false);
  // const [addOrUpdateAutoSaveEntry, setAddOrUpdateAutoSaveEntry] = useState("add");
  const addOrUpdateAutoSaveEntry = 'add';
  const autoSaveTimer = 0;
  const autoSaveIntervalRef = useRef(null);
  const { checkStatesForChanges, wantedToMove, TimeEntryData } =
    useSelector((state) => state.tab);
  const [doNotCharge, setDoNotCharge] = useState(false);
  // const [isStopped, setIsStopped] = useState(false);
  const { page } = useSelector((state) => state.timeEntry);
  const { allAutoCorrects } = useSelector((state) => state.userState);
  const isSmallScreen = useMediaQuery('(max-width:1070px)');
  const [selectedOptionTimekeeper, setSelectedOptionTimekeeper] =
    useState(null);

  const getDefaultTimeKeeper = async () => {
    await timeEntryService
      .getTimeKeepers("")
      .then((data) => {
        const timeKeeper = data.find(({ id })=>id==localStorage.getItem("empId"));
        setSelectedOptionTimekeeper(timeKeeper);
        setState((prevState) => ({
          ...prevState,
          employeeId:timeKeeper?.id
        }));
        dispatch(ToggleTimeEntryData({...INITIALSTATE,employeeId:timeKeeper}));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getInitialTimerId = () => {
    if (isOpenFromModel) {
      return selectedTimeEntry;
    }  else {
      return widgetId;
    }
  };

  const timer = useSelector(
    (state) => state.timerController.timers[getInitialTimerId()]
  );
  let isTracking =
    typeof timer !== 'undefined'
      ? timer.isTracking
      : state?.timerDto?.timerStartDateTime
      ? true
      : false;
  const [time, setTimer] = useState(0);

  useEffect(() => {
    if (allAutoCorrects?.length <= 0) {
      dispatch(getAllAutoCorrects());
    }
    getDefaultTimeKeeper();
  }, []);

  useEffect(() => {
    if (isOpenFromModel) {
      setTimer(
        typeof timer !== 'undefined' ? timer.time : previousTime
      );
    } else {
      setTimer(typeof timer !== 'undefined' ? timer.time : 0);
    }
  }, [isOpenFromModel]);

  useEffect(() => {
    if (isOpenFromcalender) {
      setState({
        ...state,
        activityDate: dayjs(activityDate).format('MM/DD/YYYY'),
        date: dayjs(activityDate),
      });
    }
  }, [isOpenFromcalender]);

  useEffect(() => {
    isOpenFromModel &&
      state?.note?.includes('DO NOT CHARGE') &&
      setDoNotCharge(true);
  }, [state]);

  useEffect(() => {
    // findUnSavedChanges(state, prevState);
  }, [autoSaveTimer]);

  useEffect(() => {
    window.onbeforeunload = function () {
      if (!jsUtils.checkIfObjectsEqual(state, prevState)) {
        return 'leave/refresh';
      } else {
        return;
      }
    };
  }, [prevState, state]);

  const saveChanges = () => {
    findUnSavedChanges(state, prevState);
  };

  const handleCloseTimeEntry = () => {
    removeWidgetFromDashboard(widgetId);
  };

  const handleRemoveWidget = () => {
    //Checking what is required for Saving before the remove of the widget.
    var RowsToBeValidate = {
      timer: time,
      clientMatter: state.customerId || 0,
      activityDate: state.activityDate,
    };
    
    if (selectedTimeEntry) {
      if (jsUtils.checkIfObjectsEqual(state, prevState)) {
        //Close edit modal
        handleClose();
      } else {
        // in edit modal we have some unsaved changess
        setModal({
          open: true,
          title: isOpenFromModel
            ? literals.SAVE_CHANGES_
            : literals.SAVE_CURRENT_TIME_ENTRY,
          message: isOpenFromModel
            ? literals.DO_YOU_WANT_TO_SAVE_TIME_ENTRY
            : literals.DO_YOU_WANT_DO_SAVE_CURRENT_TIME_ENTRY,
          cancelLabel: literals.GO_BACK,
          discardLabel: isOpenFromModel
            ? literals.DISCARD_CHANGES
            : literals.DISCARD_ENTRY,
          confirmLabel: isOpenFromModel
            ? literals.SAVE_CHANGES
            : literals.SAVE_ENTRY,
          onCancel: onCancelModal,
          onConfirm: saveChanges,
          onDiscard: onDiscardModal,
          ModalType: literals.THREE_ACTIONS,
        });
      }
    } else {
      // This is used for validating Add Time Entry Widget
      if (jsUtils.UnsavedRequiredFieldsValidation(RowsToBeValidate) ||
        state.employeeId!==Number(localStorage.getItem("empId"))) {
        //Open the Alert dialog for remove widget button
        //literal.THREE_ACTIONS for ModalType is used because there are three actions buttons in this scenario.
        setModal({
          open: true,
          title: isOpenFromModel
            ? literals.SAVE_CHANGES_
            : literals.SAVE_CURRENT_TIME_ENTRY,
          message: isOpenFromModel
            ? literals.DO_YOU_WANT_TO_SAVE_TIME_ENTRY
            : literals.DO_YOU_WANT_DO_SAVE_CURRENT_TIME_ENTRY,
          cancelLabel: literals.GO_BACK,
          discardLabel: isOpenFromModel
            ? literals.DISCARD_CHANGES
            : literals.DISCARD_ENTRY,
          confirmLabel: isOpenFromModel
            ? literals.SAVE_CHANGES
            : literals.SAVE_ENTRY,
          onCancel: onCancelModal,
          onConfirm: onConfirmModal,
          onDiscard: onDiscardModal,
          ModalType: literals.THREE_ACTIONS,
        });
      }
      // This is used for closing Add Time Entry Widget
      else {
        //Remove Time Entry Widget without asking for confirmation.
        closeModalFromCalendar();
      }
    }
  };

  const closeModalFromCalendar = () => {
    if (isOpenFromcalender) {
      handleClose();
    } else {
      handleCloseTimeEntry();
    }
  };

  const onConfirmModal = () => {
    //It is a confirmation bofy, Saving the data before the widget is removed.
    state.isPost = false;
    setisSaving(true);
    onSubmit('Save', 'closeModal');
  };

  const onCancelModal = () => {
    //It will close the modal
    setModal(null);

    if (checkStatesForChanges && wantedToMove) {
      resetAutoSaveStorage();
      setState({ ...prevState });

      // reset states
      dispatch(ToggleCheckStateForChanges(false)); // on changing tab , updated state that is useable in finding unSaved changes
      dispatch(ToggleWantedToMove('')); // store clicked tab while auto saving

      dispatch(ToggleTimeEntryTabs(wantedToMove));
    }
  };

  const onDiscardModal = (ModalType) => {
    if (ModalType === literals.TWO_ACTIONS) {
      //Resetting the fields and timer data.
      setState(INITIALSTATE);
      clearAll();
      onCancelModal();
      resetAutoSaveStorage();
      dispatch(ToggleTimeEntryData(INITIALSTATE));
    } else {
      if (isOpenFromModel || isOpenFromRecentMattersModel) {
        handleClose();
        resetAutoSaveStorage();
      } else {
        dispatch(ToggleTimeEntryData(INITIALSTATE));
        onCancelModal();
        closeModalFromCalendar();
      }
    }

    localStorage.removeItem('autoSave');
  };

  const resetAutoSaveStorage = () => {
    clearInterval(autoSaveIntervalRef.current); // time interval
    localStorage.removeItem('autoSave');
    localStorage.removeItem('showDialog');
  };

  const handleModalClose = () => {
    //Open the Alert dialog for close button
    //literal.TWO_ACTIONS for ModalType is used because there are only two actions buttons in this scenario.
    setModal({
      open: true,
      title: literals.DISCARD_TIME_ENTRY,
      message:
        literals.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_TIME_ENTRY,
      cancelLabel: literals.GO_BACK,
      discardLabel: literals.DISCARD,
      confirmLabel: '',
      onCancel: onCancelModal,
      onConfirm: onConfirmModal,
      onDiscard: onDiscardModal,
      ModalType: literals.TWO_ACTIONS,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setState({ ...state, [name]: value });
    dispatch(
      ToggleTimeEntryData({ ...TimeEntryData, [name]: value })
    );

    // Clear the corresponding error state when the input value changes
    setErrors({ ...errors, [name]: null });
  };

  const findUnSavedChanges = (state, prevState) => {
    setModal(null);

    if (!jsUtils.checkIfObjectsEqual(state, prevState)) {
      // If user had unsaved changes on changing tab and now selected to Save changes from Modal
      if (checkStatesForChanges) {
        dispatch(ToggleCheckStateForChanges(false));
      }

      onSubmit('Save');
    } else {
      if (
        !jsUtils.checkIfObjectsEqual(state, prevState) &&
        addOrUpdateAutoSaveEntry == 'add'
      ) {
        resetAutoSaveStorage();
        setisAutoSaving(false);
      }
    }
  };

  const handleDate = (date) => {
    //Converting date to the YYYY-MM-DD format as required by the API.
    setState({
      ...state,
      activityDate: date !== null && date.format('YYYY-MM-DD'),
      date: date,
    });
    setErrors({ ...errors, date: null });
  };

  const HandleAddtoHours = (Id, newTime) => {
    if (newTime !== 0) {
      const updatedTime =
        newTime < MIN_SECONDS ? MIN_SECONDS : newTime;
      const updatedHours = parseFloat(state.hours || 0);
      const AccumulatedHours =
        updatedHours +
        parseFloat(jsUtils.secondIntoHours(updatedTime));
      const updatedHoursToSeconds = jsUtils.hoursToSeconds(
        AccumulatedHours.toFixed(2)
      );

      setState((prevState) => ({
        ...prevState,
        hours: AccumulatedHours.toFixed(2),
        activityTime:
          jsUtils.formatTime(updatedHoursToSeconds) || '00:00:00',
        amount:
          state.activityType === 'TaskFee'
            ? (state.quantity * state.rate).toFixed(2)
            : state.activityType === 'SimpleFee' ? state.amount :
            (AccumulatedHours * state.rate).toFixed(2),
        quantity: state.activityType === 'TimeFee' || state.activityType === 'SimpleFee' ? AccumulatedHours.toFixed(2) : state.quantity || 0, 
      }));
      dispatch(
        ToggleTimeEntryData({
          ...TimeEntryData,
          hours: AccumulatedHours.toFixed(2),
        })
      );
    }
  };

  const handleHours = (e) => {
    let name, value;

    if (e === 'spinnerUpButton' || e === 'spinnerDownButton') {
      name = 'hours';
      const increment = e === 'spinnerUpButton' ? 0.01 : -0.01;
      value = (parseFloat(state.hours) || 0) + increment;
      value = value.toFixed(2).toString();
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    //Validating the input field to max two decimal places.
    if (jsUtils.validateHourInput(value)) {
      //Updating the state value of hour and calculation total amount for that.
      const isSimpleOrTimeFee =
        state.activityType === 'TimeFee';
      let amount = isSimpleOrTimeFee
        ? (state.rate * value).toFixed(2)
        : state.amount;

      if (isSimpleOrTimeFee) {
        setState({
          ...state,
          [name]: value,
          amount,
          quantity: value,
        });
      } else {
        setState({
          ...state,
          [name]: value,
          amount,
        });
      }

      dispatch(
        ToggleTimeEntryData({
          ...TimeEntryData,
          [name]: value,
          amount,
        })
      );

      //syncing the timer value with hours.
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleRate = (event) => {
    const { name, value } = event.target;
    //Updating the state value of hour and calculation total amount for that.

    let amount = 0.0;

    switch (state.activityType) {
      case 'TaskFee':
        if (name === 'quantity') {
          amount = parseFloat(state.rate * value).toFixed(2);
        } else if (name === 'hours') {
          amount = parseFloat(state.quantity * value).toFixed(2);
        }
        // amount == (rate * quantity)
        setState({
          ...state,
          [name]: value,
          amount,
        });
        break;
      case 'TimeFee':
      case 'SimpleFee':
        amount = parseFloat(state.hours * value).toFixed(2);
        setState({
          ...state,
          [name]: value,
          amount,
        });
        break;
    }

    dispatch(
      ToggleTimeEntryData({
        ...TimeEntryData,
        [name]: value,
        amount,
      })
    );

    //syncing the timer value with hours.
    setErrors({ ...errors, [name]: null });
  };

  const GetTotalAmount = () => {
    if (state.hours) {
      timeEntryService
        .getTotalForTimeEntry({
          timeKeeperId: state.employeeId,
          jobId: state.customerId,
          activityDate: state.date.format('YYYY-MM-DD'),
          hours: state.hours,
        })
        .then((data) => {
          setState({
            ...state,
            amount: data?.amount?.toFixed(2),
          });
        });
    }
  };

  useEffect(() => {
    if (state.activityType === 'TaskFee') {
      timeEntryService
        .getRateByTaskCode({
          taskCodeId: state.taskCodeId,
          jobId: state.customerId,
          activityDate: state.date.format('YYYY-MM-DD'),
        })
        .then((data) => {
          setState({
            ...state,
            rate: data?.rate || 0,
            amount: (state.quantity * data?.rate).toFixed(2),
          });
        });
    }
  }, [state.taskCodeId]);

  const debouncedGetTotalAmount = debounce(GetTotalAmount, 1000);

  useEffect(() => {
    if (state.activityType === 'TimeFee') {
      state.hours && debouncedGetTotalAmount();
      return () => debouncedGetTotalAmount.cancel();
    }
  }, [state.hours, state.activityType]);

  useEffect(() => {
    if (
      !isOpenFromcalender &&
      !isOpenFromModel &&
      !isOpenFromRecentMattersModel
    ) {
      if (Object.keys(TimeEntryData).length) {
        setState({
          ...state,
          description: TimeEntryData?.description,
          note: TimeEntryData?.note,
          customerId: TimeEntryData?.customerId?.matterId,
          activityType: TimeEntryData?.activityType
            ? TimeEntryData.activityType
            : 'TimeFee',
          hours: TimeEntryData?.hours,
          quantity: TimeEntryData?.quantity,
          rate: TimeEntryData?.rate,
          amount: TimeEntryData?.amount,
          billableCode: TimeEntryData?.customerId
            ? TimeEntryData?.customerId?.billableType?.id
              ? 'Yes'
              : 'No'
            : '',
          taxExempt: TimeEntryData?.employeeId
            ? TimeEntryData?.employeeId?.employeeEntity?.taxExempt
              ? 'Yes'
              : 'No'
            : '',
        });
      }
    }
  }, []);

  const postData = async (check) => {
    //Mapping the state to the model that is required for the request.
    let newState = { ...state };
    newState.hours = state.hours == '' ? 0 : state.hours;
    var ObjectModel = jsUtils.mapObjectToModel(
      timeEntryModel,
      newState
    );
    ObjectModel.hours = parseFloat(ObjectModel.hours);
    ObjectModel.doNotCharge = doNotCharge;
    ObjectModel.activityDate = dayjs(ObjectModel.activityDate).format(
      'YYYY-MM-DD'
    );
    console.log(ObjectModel)

    let serviceFunction;
    // Determine the service function based on isPost
    if (isOpenFromModel || addOrUpdateAutoSaveEntry === 'update') {
      if (state.isPost) {
        serviceFunction = timeEntryService.postTimeEntry;
      } else if (state?.status === literals.POSTED) {
        serviceFunction = timeEntryService.savePostedTimeEntry;
      } else {
        serviceFunction = timeEntryService.updateTimeEntry;
      }
      ObjectModel.activityId = state.activityId;
    } else {
      if (state.isPost) {
        serviceFunction = timeEntryService.postTimeEntry;
      } else {
        serviceFunction = timeEntryService.addTimeEntry;
        ObjectModel.timerEventRequests = jsUtils.getTimerDto(widgetId);
      }
    }
    
    try {
      const { data } = await serviceFunction(ObjectModel);
      setisPosting(false);
      setisSaving(false);
      if (!isOpenFromModel) {
        dispatch(
          resetTimer({
            timerId: isOpenFromModel ? selectedTimeEntry : widgetId,
          })
        );
      }
      const { timeEntryDto, complianceMessages } = getResponse(data);

      handleResponse(timeEntryDto, complianceMessages, check);
    } catch (e) {
      handleCatch(e);
    }
  };

  const getResponse = (data) => {
    if (state.isPost) {
      return data.postTimeEntry;
    } else if (state?.status === literals.POSTED) {
      return data.savePostedTimeEntry;
    } else {
      return data.addUpdateTimeEntryDraft;
    }
  };

  const handleResponse = (
    timeEntryDto,
    complianceMessages,
    check
  ) => {
    if (timeEntryDto) {
      dispatch(
        openSnackbar({
          message:
            timeEntryDto.statusId === 3 &&
            state.status === literals.DRAFT
              ? literals.TIME_ENTRY_POSTED
              : literals.TIME_ENTRY_SAVED,
          severity: literals.SUCCESS,
        })
      );

      //Resetting Error State
      setErrors({});
      // Auto saving entry
      if (isAutoSaving && check == 'autoSave') {
        let updatedState = {
          ...state,
          activityId: timeEntryDto?.activityId,
        };
        setState(updatedState);
        setPrevState(updatedState);
        dispatch(fetchTimeEntryList({ page }));

        if (checkStatesForChanges && wantedToMove) {
          dispatch(ToggleTimeEntryTabs(wantedToMove));
          dispatch(ToggleWantedToMove(''));
        }
      } else {
        dispatch(fetchTimeEntryList({ page }));
        setState({...INITIALSTATE,employeeId: timeEntryDto?.timekeeper?.employeeId});
        setisAutoSaving(false);
        setPrevState({});
        setDoNotCharge(false);
        handleClose && handleClose();
      }

      jsUtils.resetTimerDto(widgetId);
      localStorage.removeItem('autoSave');
      clearAll();
      dispatch(ToggleTimeEntryData(INITIALSTATE));
    } else {
      if (complianceMessages.length > 0) {
        const accumulatedErrors = {};
        complianceMessages.forEach((error) => {
          accumulatedErrors[jsUtils.getFieldName(error.field)] = {
            message: error.message,
            errorLevel: error.errorLevel,
          };

          if (error.field === 'Narrative') {
            error.canHighlightErrors &&
              setState({
                ...state,
                description: jsUtils.updateComplianceErrors(
                  state.description,
                  error.regex,
                  error.isRegexCaseSensitive
                ),
              });
          }
        });

        setErrors((prevErrors) => ({
          ...prevErrors,
          ...accumulatedErrors,
        }));
      }
      dispatch(
        openSnackbar({
          message: state.isPost
            ? literals.TIME_ENTRY_CANT_POSTED
            : literals.TIME_ENTRY_CANT_SAVED,
          severity: literals.ERROR,
        })
      );
    }
  };

  const handleCatch = (e) => {
    dispatch(
      openSnackbar({
        message: literals.SOMETHING_WENT_WRONG,
        severity: literals.ERROR,
      })
    );
    setisPosting(false);
    setisSaving(false);
    console.error(e);
  };

  const GetSecondaryComponents = () => {
    return (
      <div
        className="secondarytimer"
        style={{ gap: isTracking ? 80 : 150, position: 'relative' }}
      >
        <div
          style={{
            position: 'absolute',
            right: isSmallScreen ? 95 : 165,
          }}
        >
          <CustomTimer
            Component="TimeEntry"
            previousTime={time}
            timerDTO={state?.timerDto}
            status={state.status}
            Id={isOpenFromModel ? selectedTimeEntry : widgetId}
            isActivitiyId={isOpenFromModel}
            handleAddtoHours={HandleAddtoHours}
          />
        </div>
        {/* Remove/Close Widget */}
        <div>
          <Tooltip
            title={
              isOpenFromModel || isOpenFromcalender
                ? literals.CLOSE
                : literals.REMOVE_WIDGET
            }
          >
            <IconButton onClick={() => handleRemoveWidget()}>
              <CloseIcon
                className="icons"
                style={{ color: '#0050C3' }}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  const onSubmit = (action, type) => {
    //Two different schemas are defined for Save  and Post actions
    var schema =
      action === literals.POST
        ? TimeEntryPostSchema
        : TimeEntrySaveSchema;

    schema
      .validate(state, { abortEarly: false })
      .then(() => {
        if (action === literals.POST) {
          setisPosting(true);
          state.isPost = true;
          setisAutoSaving(false);
        } else {
          setisSaving(true);
          state.isPost = false;
        }

        // If time entry is added by Save button click not by auto-saving
        type == 'autoSave' ? postData('autoSave') : postData('');

        if (type == 'closeModal') {
          onDiscardModal('Three Actions');
        }
      })
      .catch((err) => {
        // if state is invalid, update Errors state with error messages
        const newErrors = {};
        if (err && err.inner) {
          err.inner.forEach((error) => {
            newErrors[error.path] = {
              message: error.message,
              errorLevel: 'DisablePost',
            };
          });
        }
        setErrors(newErrors);
        setisSaving(false);

        if (type == 'closeModal') {
          setModal(null);
        }
      });
  };
  const handleUnPost = () => {
    const activityId =  state?.activityId;
    setIsUnPost(true);
    timeEntryService
        .unpostTimeEntry(activityId)
        .then((data) => {
          if(data?.data?.unpostTimeEntry) {
            dispatch(
              openSnackbar({
                message: literals.UNPOST_SUCCESS,
                severity: literals.SUCCESS,
              })
            );
            setState({
              ...state,
              status: 'Draft',
            });
            setIsUnPost(false);
            dispatch(fetchTimeEntryList({ page }));
          } 
        });
  }
  const handleDoNotCharge = () => {
    if (!doNotCharge) {
      if (state?.note?.length < 1986) {
        setState({
          ...state,
          note:
            state.note.length === 0
              ? state.note.concat('DO NOT CHARGE')
              : state.note.concat('\nDO NOT CHARGE'),
        });
        setDoNotCharge(!doNotCharge);
      } else {
        dispatch(
          openSnackbar({
            message: literals.MAXIMUM_CHARACTERS_EXCEEDED,
            severity: literals.ERROR,
          })
        );
      }
    } else {
      setState({
        ...state,
        note: state.note.replace('DO NOT CHARGE', '').trim(),
      });
      setDoNotCharge(!doNotCharge);
    }
  };

  const handleDiscount = (value) => {
    let updatedNote = '';

    if (state.note?.length > 0) {
      const availableLength = 2000 - value.length;
      if (state?.note?.length < availableLength) {
        if (state?.note?.includes('Courtesy Discount:')) {
          let lineBreakedSplittedText = state?.note?.split(/^/gm);

          let indexTORemove = lineBreakedSplittedText.findIndex(
            (item) => item.includes('Courtesy Discount:')
          );

          lineBreakedSplittedText.splice(indexTORemove, 1);

          lineBreakedSplittedText.push(
            indexTORemove !== lineBreakedSplittedText.length
              ? '\u000A' + value
              : value
          );

          updatedNote = lineBreakedSplittedText
            .toString()
            .replaceAll(',', '');
        } else {
          updatedNote = state.note + '\u000A' + value;
        }
        setState({ ...state, note: updatedNote });
      } else {
        dispatch(
          openSnackbar({
            message: literals.MAXIMUM_CHARACTERS_EXCEEDED,
            severity: literals.ERROR,
          })
        );
      }
    } else {
      updatedNote = value;
      setState({ ...state, note: updatedNote });
    }
  };
  return (
    <ApolloContextProvider uri="/time-management/graphql">
      <form>
        <MainCard
          className="time-entry-main-card"
          secondary={GetSecondaryComponents()}
          isOpenFromModel={
            isOpenFromModel ||
            isOpenFromRecentMattersModel ||
            isOpenFromcalender
          }
        >
          <Grid container spacing={2}>
            <TimeEntryFields
              DNC={doNotCharge}
              handleDNC={handleDoNotCharge}
              handleChange={handleChange}
              state={state}
              errors={errors}
              setState={setState}
              setErrors={setErrors}
              handleDate={handleDate}
              handleHours={handleHours}
              handleRate={handleRate}
              isTracking={isTracking}
              handleDiscount={handleDiscount}
              isOpenFromModel={
                isOpenFromModel ||
                isOpenFromRecentMattersModel ||
                isOpenFromcalender
              }
              setPrevState={setPrevState}
              activityId={isOpenFromModel ? selectedTimeEntry : null}
              dictionary={allAutoCorrects}
              selectedOptionTimekeeper={selectedOptionTimekeeper}
              setSelectedOptionTimekeeper={setSelectedOptionTimekeeper}
            />

            {/* View More */}
            <Grid item xs={12} sm={12} md={12} lg={12} x1={12}>
              <div className="view-more-button">
                <Stack>
                  {
                    // if there is some entry selected than remove cancel button
                    !isOpenFromModel ? (
                      <StyledButton
                        style={{
                          color: '#0050C3',
                          paddingLeft: '0px',
                        }}
                        onClick={() => handleModalClose()}
                      >
                        {literals.CLEAR_ENTRY}
                      </StyledButton>
                    ) : (
                      <div></div>
                    )
                  }
                </Stack>
                <Stack>
               { isOpenFromModel && <StyledLoadingButton
                    onClick={() => handleUnPost(literals.UNPOST)}
                    id={literals.UNPOST}
                    variant="contained"
                    disabled={state?.status !== "Posted"}
                    style={{
                      backgroundColor: '#0050C3',
                      minWidth: '48px',
                      maxWidth: '60px',
                    }}
                    loading={isUnPost}
                  >
                    {literals.UNPOST}
                  </StyledLoadingButton>
                }
                  <StyledLoadingButton
                    disabled={jsUtils.getSubmitButtonDisableStatus(
                      'save',
                      state?.status,
                      isTracking
                    )}
                    onClick={() => onSubmit(literals.SAVE)}
                    id={literals.SAVE}
                    variant="outlined"
                    style={{
                      color: '#0050C3',
                      minWidth: '48px',
                      maxWidth: '48px',
                    }}
                    loading={isSaving}
                  >
                    {literals.SAVE}
                  </StyledLoadingButton>
                  <StyledLoadingButton
                    disabled={jsUtils.getSubmitButtonDisableStatus(
                      'post',
                      state?.status,
                      isTracking
                    )}
                    onClick={() => onSubmit(literals.POST)}
                    id={literals.POST}
                    variant="contained"
                    style={{
                      backgroundColor: '#0050C3',
                      minWidth: '48px',
                      maxWidth: '48px',
                    }}
                    loading={isPosting}
                  >
                    {literals.POST}
                  </StyledLoadingButton>
                </Stack>
              </div>
            </Grid>
          </Grid>
        </MainCard>
      </form>
      {Modal !== null && <AlertDialog {...Modal} />}
    </ApolloContextProvider>
  );
};

export default memo(AddTimeEntry);
